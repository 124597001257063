import axios from "axios";
import moment from "moment";

const environment = process.env.NODE_ENV;
let KEY = process.env.REACT_APP_DEVELOPMENT_API_KEY;
let baseURL = process.env.REACT_APP_API_DEVELOPMENT_URL;

if (environment === "production") {
  KEY = process.env.REACT_APP_PRODUCTION_API_KEY;
  baseURL = process.env.REACT_APP_API_PRODUCTION_URL;
}
if (environment === "test") {
  KEY = process.env.REACT_APP_TEST_API_KEY;
  baseURL = process.env.REACT_APP_API_TEST_URL;
}

const contentType = "application/json";

export const upsertContract = async (
  address1,
  address2,
  brand,
  cellPhone,
  city,
  dealerCustomerId,
  email,
  firstName,
  lastName,
  modelNumber,
  otherPhone,
  recallCampaign,
  serialNumber,
  stateProvince,
  zipCode
) => {
  return await axios(
    baseURL + "standard/recall/createcontract/initialcontract",
    {
      method: "POST",
      headers: {
        "Content-Type": contentType,
        Authorization: KEY,
      },
      data: {
        contract: {
          contractPurchaseDate: moment().format("YYYY-MM-DD"),
          contractOtherTax: 0,
          contractRetailPrice: 795.0,
          contractSalesTax: 0,
          contractSKU:
            recallCampaign === "GeRecallFreezerHandle"
              ? "GERECALLFREEZERHANDLE"
              : "GECookRecall",
          contractSourceName: "GEMA",
          dealerID: "U402GE2RECALL",
          dealerInvoiceNumber: serialNumber,
        },
        customer: {
          address1,
          address2,
          businessName: "",
          cellPhone,
          city,
          countryCode: "USA",
          dealerCustomerID:
            recallCampaign === "GeRecallFreezerHandle"
              ? dealerCustomerId
              : "10013",
          email,
          firstName,
          languageCode: "en-us",
          lastName,
          otherPhone,
          stateProvince,
          zipCode,
        },
        general1: "",
        general2: "",
        general3: "",
        general4: "",
        product: {
          catalogItemNumber: "1",
          manufacturerName: brand,
          modelName:
            recallCampaign === "GeRecallFreezerHandle"
              ? "FREEZERS & ICE MAKERS"
              : "RANGE / OVEN / COOKTOP",
          modelNumber,
          oemLaborWarrantyTerm: "0",
          oemMajorCompTerm: "0",
          oemOtherTerm: "0",
          oemPartsWarrantyTerm: "0",
          productCondition: "U",
          productDeliveryInstallDate: "2021-10-01",
          productPurchaseDate: "2021-10-01",
          productPurchasePrice: 3000.0,
          serialNumber,
          serviceLocation: "In Home",
          subCategory:
            recallCampaign === "GeRecallFreezerHandle" ? "FRZR1" : "COOK",
        },
      },
    }
  );
};

export const getContractDetails = async (contractNumber) => {
  return await axios(baseURL + "standard/OPgetcontract/details", {
    method: "POST",
    headers: {
      "Content-Type": contentType,
      Authorization: KEY,
    },
    data: {
      onPointContractNumber: contractNumber,
    },
  });
};

export const getServiceOrders = async (contractNumber) => {
  return await axios(baseURL + "standard/lookups/getserviceorders", {
    method: "POST",
    headers: {
      "Content-Type": contentType,
      Authorization: KEY,
    },
    data: {
      serviceAvengerContractNumber: contractNumber,
    },
  });
};

export const getAppointments = async (
  address1,
  address2,
  cellPhone,
  city,
  email,
  firstName,
  lastName,
  otherPhone,
  stateProvince,
  zipCode,
  brandCode,
  modelNumber,
  serialNumber,
  beginSearchDate,
  contractNumber,
  beginDt,
  endDt,
  recallCampaign
) => {
  return await axios(baseURL + "standard/scheduling/serviceappointment", {
    method: "POST",
    headers: {
      "Content-Type": contentType,
      Authorization: KEY,
    },
    data: {
      customer: {
        customerId: "",
        firstName,
        lastName,
        businessName: "",
        address1,
        address2,
        city,
        stateProvince,
        zipCode,
        cellPhone,
        otherPhone,
        email,
        countryCode: "USA",
      },
      product: {
        brandCode,
        productCode:
          recallCampaign === "GeRecallFreezerHandle" ? "FRZR1" : "DW",
        modelNumber,
        serialNumber,
        installDate: "2021-10-01",
      },
      warranty: {
        warrantyType: "Service Contract",
        coverageBeginDate: moment(beginDt).format("YYYY-MM-DD"),
        coverageEndDate: moment(endDt).format("YYYY-MM-DD"),
        contractNumber,
      },
      service: {
        serviceType: "Repair",
        serviceElevation: "Normal",
        serviceNotice: "First Notice",
        procedureCode: "Field Service",
        numberOfDays: "30",
        beginSearchDate,
        sessionType: "OT",
      },
    },
  });
};

export const getBookedAppointment = async (
  contractNumber,
  serviceOrderNumber
) => {
  return await axios(baseURL + "standard/lookups/getbookedappointments", {
    method: "POST",
    headers: {
      "Content-Type": contentType,
      Authorization: KEY,
    },
    data: {
      serviceAvengerContractNumber: contractNumber,
      serviceOrderNumber: serviceOrderNumber,
    },
  });
};

export const rebookAppointment = async (
  contractNumber,
  externalContractNumber,
  externalOrderNumber,
  serviceOrderNumber,
  firstName,
  lastName,
  primaryPhone,
  selectedAppointment
) => {
  const { appointmentInfo } = selectedAppointment;
  const { timeSlotData, svcrPhone, svcrName, serviceCenterID } =
    appointmentInfo;
  const { svcrID, availableDate, timePeriod } = timeSlotData;

  return await axios(baseURL + "standard/change/serviceappointment", {
    method: "POST",
    headers: {
      "Content-Type": contentType,
      Authorization: KEY,
    },
    data: {
      serviceAvengerContractNumber: contractNumber,
      externalContractNumber,
      externalOrderNumber,
      serviceOrderNumber,
      servicerSource: "OT",
      crmKey: "",
      firstName,
      lastName,
      consumerPhone: primaryPhone,
      productSeqeuenceNumber: "1",
      servicerID: serviceCenterID,
      appointmentDate: moment(availableDate).format("YYYY-MM-DD"),
      appointmentTimeSlot: timePeriod,
      serviceType: "Standard",
      serviceLocation: "In Home",
      servicerName: svcrName,
      servicerPhone: svcrPhone,
      servicerWebsite: "",
      groupID: svcrID,
    },
  });
};

export const bookAppointment = async (
  firstName,
  lastName,
  address1,
  address2,
  city,
  stateProvince,
  zipCode,
  cellPhone,
  otherPhone,
  email,
  brandCode,
  modelNumber,
  serialNumber,
  coverageBeginDate,
  coverageEndDate,
  serviceAvengerContractNumber,
  externalContractNumber,
  selectedAppointment,
  recallCampaign
) => {
  const { appointmentInfo } = selectedAppointment;
  const { timeSlotData, svcrPhone, svcrName, serviceCenterID } =
    appointmentInfo;
  const { svcrID, offerID, availableDate, timePeriod } = timeSlotData;

  return await axios(baseURL + "standard/booking/serviceappointment", {
    method: "POST",
    headers: {
      "Content-Type": contentType,
      Authorization: KEY,
    },
    data: {
      customer: {
        firstName,
        lastName,
        businessName: null,
        address1,
        address2,
        city,
        stateProvince,
        zipCode,
        cellPhone,
        otherPhone,
        email,
        countryCode: "USA",
        crmKey: "",
      },
      product: {
        sequenceNumber: 1,
        brandCode,
        productCode:
          recallCampaign === "GeRecallFreezerHandle" ? "FRZR1" : "COOK",
        modelNumber,
        serialNumber,
        installDate: "2021-10-01",
      },
      warranty: {
        warrantyType: "Service Contract",
        coverageBeginDate: moment(coverageBeginDate).format("YYYY-MM-DD"),
        coverageEndDate: moment(coverageEndDate).format("YYYY-MM-DD"),
        serviceAvengerContractNumber,
        externalContractNumber,
      },
      service: {
        serviceOrderNumber: "",
        serviceCallDate: moment().format("YYYY-MM-DD"),
        failureDate: moment().format("YYYY-MM-DD"),
        serviceRequestType: "Repair",
        serviceElevation: "Normal",
        serviceSite: "In Home",
        failureType: "Standard",
        functionalityStatus: "Functional",
        symptomCode: "Recall",
        failureNote: "recall service",
      },
      appointment: {
        groupID: svcrID,
        offerKey: offerID,
        appointmentDate: moment(availableDate).format("YYYY-MM-DD"),
        appointmentTimeSlot: timePeriod,
        consumerRequestDate: moment(availableDate).format("YYYY-MM-DD"),
        consumerRequestTime: timePeriod,
        specialInstructions: "",
      },
      servicer: {
        servicerID: serviceCenterID,
        servicerName: svcrName,
        servicerPhone: svcrPhone,
        servicerWebsite: "",
      },
      parts: {
        partDistibutorID: "",
        partDistributorName: "",
        partNumber: "",
        partDescription: "",
        partQuantity: 0,
        partPrice: 0,
        shipperCompany: "",
        shipperTracking: "",
      },
      payment: {
        deductible: 0,
        poNumber: "",
        poAmount: 0,
        authorizationNumber: "",
        billTo: "",
        notToExceedAmount: 500,
      },
      additionalFunctions: {
        status1: "OT",
        status2: "",
        status3: "",
        status4: "",
        status5: "",
        status6: "",
        status7: "",
        status8: "",
        status9: "",
        status10: "",
      },
    },
  });
};

export const cancelAppointment = async (
  contractNumber,
  externalContractNumber,
  externalOrderNumber,
  serviceOrderNumber,
  firstName,
  lastName,
  primaryPhone,
  bookedAppointment,
  cancelReason
) => {
  const {
    appointmentDate,
    appointmentTimeSlot,
    servicerID,
    servicerName,
    servicerPhone,
    servicerWebsite,
  } = bookedAppointment;

  return await axios(baseURL + "standard/cancel/serviceappointment", {
    method: "POST",
    headers: {
      "Content-Type": contentType,
      Authorization: KEY,
    },
    data: {
      serviceAvengerContractNumber: contractNumber,
      externalContractNumber,
      externalOrderNumber,
      serviceOrderNumber,
      servicerSource: "OT",
      crmKey: "",
      firstName,
      lastName,
      consumerPhone: primaryPhone,
      productSeqeuenceNumber: "1",
      servicerID,
      appointmentDate: moment(appointmentDate).format("YYYY-MM-DD"),
      appointmentTimeSlot: appointmentTimeSlot,
      serviceType: "Standard",
      serviceLocation: "In Home",
      servicerName,
      servicerPhone,
      servicerWebsite,
      cancelReason,
    },
  });
};
