import React, { Component } from "react";
import { Card, Col, ListGroup, Row, Spinner } from "react-bootstrap";
import moment from "moment";

class ServiceOrderList extends Component {
  getVariant = (serviceOrder) => {
    const { selectedServiceOrder } = this.props;
    if (
      selectedServiceOrder?.serviceOrderNumber ===
        serviceOrder?.serviceOrderNumber &&
      selectedServiceOrder !== null
    ) {
      return "warning";
    } else {
      return serviceOrder === null ? "light" : null;
    }
  };

  getSubcategory = (serviceOrder) => {
    return serviceOrder?.subCategory !== null &&
      serviceOrder?.subCategory?.length > 0
      ? " - " + serviceOrder?.subCategory
      : null;
  };

  renderServiceOrders = () => {
    const { serviceOrderList, handleServiceOrderSelect } = this.props;
    if (serviceOrderList?.length > 0) {
      return serviceOrderList?.map((serviceOrder) => {
        return (
          <ListGroup.Item
            key={serviceOrder?.serviceOrderNumber}
            variant={this.getVariant(serviceOrder)}
            onClick={() => handleServiceOrderSelect(serviceOrder, 1)}
            className="ServiceOrderListItem"
          >
            <b>{serviceOrder?.serviceOrderNumber}</b>
            {this.getSubcategory(serviceOrder)}
            <br />
            {"Filed: " +
              moment(serviceOrder?.claimDateConsumerFiled).format("MM/DD/YYYY")}
            <br />

            {" SEQ#: "}
            {serviceOrder?.productSequenceNumber}
            <br />
            {" Claim Status: "}
            {serviceOrder?.claimStatus === null
              ? "Unknown"
              : serviceOrder?.claimStatus}
          </ListGroup.Item>
        );
      });
    } else {
      return <ListGroup.Item>No service orders exist.</ListGroup.Item>;
    }
  };

  render() {
    const { serviceOrderWorking } = this.props;
    return (
      <Card className="mt-3">
        <Card.Header className="text-white">Service Orders</Card.Header>
        <Card.Body className="ServiceOrderList">
          {serviceOrderWorking && (
            <Row className="mt-3">
              <Col>
                <Spinner animation="border" variant="secondary"></Spinner>
                {"  "}
                Getting service orders...
              </Col>
            </Row>
          )}
          {!serviceOrderWorking && (
            <ListGroup variant="flush">{this.renderServiceOrders()}</ListGroup>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default ServiceOrderList;
