import React from "react";
import moment from "moment";
import { Col, Row, Spinner } from "react-bootstrap";
import AppointmentDay from "./AppointmentDay";

class AppointmentList extends React.Component {
  state = { dates: [] };
  componentDidMount() {
    this.filterDates();
  }

  componentWillUnmount() {
    this.setState({ dates: [] });
  }

  filterDates() {
    let currentDate = moment(this.props.beginSearchDate, "MM-DD-YYYY");
    var dateList = [];

    for (let i = 0; i < 7; i++) {
      var appointmentList = [];
      if (moment(currentDate).isSameOrBefore(moment().format("MM/DD/YYYY"))) {
        // Don't add any appointments if today or before
        dateList.push({
          date: moment(currentDate).format("MM/DD/YYYY"),
          appointmentList: [],
        });
      } else {
        if (this.props.appointmentList.length > 0) {
          appointmentList = this.props.appointmentList.filter(
            // eslint-disable-next-line
            (appointment) =>
              moment(
                appointment.appointmentInfo.timeSlotData.availableDate
              ).format("YYYY/MM/DD") ===
              moment(currentDate).format("YYYY/MM/DD")
          );
        }
        dateList.push({
          date: moment(currentDate).format("MM/DD/YYYY"),
          appointmentList: appointmentList,
        });
      }

      currentDate = moment(currentDate).add(1, "days");
    }

    this.setState({ dates: dateList });
  }

  displayGrid() {
    const { bookedAppointment, handleAppointmentSelect, selectedAppointment } =
      this.props;
    return this.state.dates.map((date) => {
      return (
        <AppointmentDay
          key={date.date}
          date={date}
          handleAppointmentSelect={handleAppointmentSelect}
          selectedAppointment={selectedAppointment}
          bookedAppointment={bookedAppointment}
        />
      );
    });
  }

  render() {
    const { appointmentWorking } = this.props;
    return (
      <React.Fragment>
        {appointmentWorking && (
          <Row className="mt-3 ps-3 pe-3">
            <Col>
              <Spinner animation="border" variant="secondary"></Spinner>
              {"  "}
              Booking appointment...
            </Col>
          </Row>
        )}
        {!appointmentWorking && (
          <Row className="mt-3 ps-3 pe-3">{this.displayGrid()}</Row>
        )}
      </React.Fragment>
    );
  }
}

export default AppointmentList;
