import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Image, Row } from "react-bootstrap";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/login";
import Recall from "./components/recall";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Row className="mt-5">
              <Col xs={6} lg={3} className="mx-auto">
                <Image src="/onpoint-logo.png" fluid />
              </Col>
            </Row>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/sign-in" component={Login} />
              <ProtectedRoute path="/recall" component={Recall} />
              <Route path="*" component={Login} />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
