import moment from "moment";

class Auth {
  constructor() {
    this.username = "GeRecallUser";
    this.freezerRecallUsername = "GeRecallFreezerHandle";
  }

  login(usernameInput, passwordInput, successCB, failCB) {
    let today = moment();
    let year = today.get("year");
    let month = today.get("month") + 1;
    let date = today.get("date");

    let monthString = month < 10 ? "0" + month.toString() : month.toString();
    let yearString = year.toString();
    let dateString = date < 10 ? "0" + date.toString() : date.toString();
    let currentPassword = "R3C@L1" + yearString + monthString + dateString;

    if (
      (usernameInput === this.username ||
        usernameInput === this.freezerRecallUsername) &&
      passwordInput === currentPassword
    ) {
      sessionStorage.setItem("isAuthenticated", true);
      sessionStorage.setItem("loginDt", today);
      sessionStorage.setItem("username", usernameInput);
      successCB();
    } else {
      sessionStorage.clear();
      sessionStorage.setItem("isAuthenticated", false);
      failCB();
    }
  }

  logout(cb) {
    sessionStorage.clear();
    sessionStorage.setItem("isAuthenticated", false);
    cb();
  }

  isAuthenticated() {
    let today = moment();
    let year = today.get("year");
    let month = today.get("month") + 1;
    let date = today.get("date");

    let loginDt = sessionStorage.getItem("loginDt");
    let loginYear = 0;
    let loginMonth = 0;
    let loginDate = 0;

    if (loginDt) {
      loginYear = moment(this.loginDt).get("year");
      loginMonth = moment(this.loginDt).get("month") + 1;
      loginDate = moment(this.loginDt).get("date");
    }

    let isAuthenticated = sessionStorage.getItem("isAuthenticated");

    if (
      isAuthenticated &&
      loginYear === year &&
      loginMonth === month &&
      loginDate === date
    ) {
      return true;
    } else {
      sessionStorage.clear();
      return false;
    }
  }
}

export default new Auth();
