import React, { Component } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import moment from "moment";

class ExistingAppointment extends Component {
  formatPhoneNumber = (str) => {
    let cleaned = ("" + str).replace(/\D/g, "");
    let match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  getAppointmentDetails = () => {
    const {
      existingAppointment,
      handleCancelAppointment,
      handleInputChange,
      appointmentWorking,
      cancelReason,
      isCancelReasonValid,
      isCancelReasonValidated,
    } = this.props;
    if (existingAppointment !== null) {
      const {
        appointmentDate,
        appointmentTimeSlot,
        servicerName,
        servicerPhone,
      } = existingAppointment;

      return (
        <React.Fragment>
          <p>
            <b>
              {moment.utc(appointmentDate).format("MM/DD/YYYY")} {" from "}
              {appointmentTimeSlot}
            </b>
            <br />
            {servicerName}
            <br />
            {this.formatPhoneNumber(servicerPhone)}
          </p>
          <Form.Group controlId="cancelReason" className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control
              autoComplete="none"
              type="text"
              placeholder="cancel reason"
              value={cancelReason}
              onChange={(event) => {
                handleInputChange(event);
              }}
              disabled={
                appointmentWorking ||
                appointmentTimeSlot?.toUpperCase() === "CANCELLED"
              }
              className={
                !isCancelReasonValidated
                  ? null
                  : isCancelReasonValid
                  ? "is-valid"
                  : "is-invalid"
              }
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              Please provide a cancel reason.
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            className="m-1"
            disabled={
              appointmentWorking ||
              appointmentTimeSlot?.toUpperCase() === "CANCELLED"
            }
            onClick={(e) => {
              e.preventDefault();
              handleCancelAppointment();
            }}
            variant="secondary"
          >
            Cancel
          </Button>
        </React.Fragment>
      );
    } else {
      return "No service order has been selected or there is no appointment scheduled.";
    }
  };
  render() {
    const { appointmentWorking, getAppointmentWorking } = this.props;
    return (
      <Card className="mt-3">
        <Card.Header className="text-white">Existing Appointment</Card.Header>
        <Card.Body className="ExistingAppointment">
          {appointmentWorking && (
            <Row className="mt-3">
              <Col>
                <Spinner animation="border" variant="secondary"></Spinner>
                {"  "}
                Booking appointment...
              </Col>
            </Row>
          )}
          {getAppointmentWorking && (
            <Row className="mt-3">
              <Col>
                <Spinner animation="border" variant="secondary"></Spinner>
                {"  "}
                Searching for existing appointment...
              </Col>
            </Row>
          )}
          {!appointmentWorking &&
            !getAppointmentWorking &&
            this.getAppointmentDetails()}
        </Card.Body>
      </Card>
    );
  }
}

export default ExistingAppointment;
