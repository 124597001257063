import React, { Component } from "react";
import moment from "moment";
import { Card, Col, ListGroup } from "react-bootstrap";
import Appointment from "./Appointment";

class AppointmentDay extends Component {
  state = {
    morningAppointment: null,
    afternoonAppointment: null,
    eveningAppointment: null,
    allDayAppointment: null,
  };

  componentDidMount() {
    const { date } = this.props;
    const { appointmentList } = date;
    let mornings = [],
      afternoons = [],
      evenings = [],
      allDays = [];

    if (appointmentList.length > 0) {
      mornings = appointmentList.filter(
        (appointment) =>
          appointment.appointmentInfo.timeSlotData.timePeriodSlotDescription.toLowerCase() ===
          "morning"
      );

      afternoons = appointmentList.filter(
        (appointment) =>
          appointment.appointmentInfo.timeSlotData.timePeriodSlotDescription.toLowerCase() ===
          "afternoon"
      );

      evenings = appointmentList.filter(
        (appointment) =>
          appointment.appointmentInfo.timeSlotData.timePeriodSlotDescription.toLowerCase() ===
          "evening"
      );

      allDays = appointmentList.filter(
        (appointment) =>
          appointment.appointmentInfo.timeSlotData.timePeriodSlotDescription.toLowerCase() ===
          "all day"
      );
    }

    if (mornings.length > 0) {
      mornings.sort(
        (a, b) =>
          parseFloat(a.appointmentInfo.sortOrder) -
          parseFloat(b.appointmentInfo.sortOrder)
      );
    }

    if (afternoons.length > 0) {
      afternoons.sort(
        (a, b) =>
          parseFloat(a.appointmentInfo.sortOrder) -
          parseFloat(b.appointmentInfo.sortOrder)
      );
    }

    if (evenings.length > 0) {
      evenings.sort(
        (a, b) =>
          parseFloat(a.appointmentInfo.sortOrder) -
          parseFloat(b.appointmentInfo.sortOrder)
      );
    }

    if (allDays.length > 0) {
      allDays.sort(
        (a, b) =>
          parseFloat(a.appointmentInfo.sortOrder) -
          parseFloat(b.appointmentInfo.sortOrder)
      );
    }

    this.setState({
      morningAppointment: mornings.length > 0 ? mornings[0] : null,
      afternoonAppointment: afternoons.length > 0 ? afternoons[0] : null,
      eveningAppointment: evenings.length > 0 ? evenings[0] : null,
      allDayAppointment: allDays.length > 0 ? allDays[0] : null,
    });
  }

  getWeekDay() {
    let dayOfWeek = moment(this.props.date.date).day();
    switch (dayOfWeek) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "";
    }
  }

  render() {
    const {
      afternoonAppointment,
      allDayAppointment,
      eveningAppointment,
      morningAppointment,
    } = this.state;

    const {
      bookedAppointment,
      date,
      handleAppointmentSelect,
      selectedAppointment,
    } = this.props;

    return (
      <Col>
        <Card className="text-center mb-2" bg="primary" text="white">
          <Card.Header>
            {this.getWeekDay()}
            <br />
            {date.date}
          </Card.Header>
          <ListGroup variant="flush">
            <Appointment
              appointment={morningAppointment}
              handleAppointmentSelect={handleAppointmentSelect}
              selectedAppointment={selectedAppointment}
              bookedAppointment={bookedAppointment}
              timeOfDay="M: "
            />
            <Appointment
              appointment={afternoonAppointment}
              handleAppointmentSelect={handleAppointmentSelect}
              selectedAppointment={selectedAppointment}
              bookedAppointment={bookedAppointment}
              timeOfDay="A: "
            />
            <Appointment
              appointment={eveningAppointment}
              handleAppointmentSelect={handleAppointmentSelect}
              selectedAppointment={selectedAppointment}
              bookedAppointment={bookedAppointment}
              timeOfDay="E: "
            />
            <Appointment
              appointment={allDayAppointment}
              handleAppointmentSelect={handleAppointmentSelect}
              selectedAppointment={selectedAppointment}
              bookedAppointment={bookedAppointment}
              timeOfDay="AD: "
            />
          </ListGroup>
        </Card>
      </Col>
    );
  }
}

export default AppointmentDay;
