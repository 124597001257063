import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import "./Appointment.css";

class Appointment extends Component {
  getClassName() {
    const { selectedAppointment, appointment } = this.props;

    if (selectedAppointment === appointment && selectedAppointment !== null) {
      return "text-center selectedAppointment text-white";
    } else {
      return appointment === null
        ? "text-center"
        : "text-center text-white availableAppointment";
    }
  }

  getVariant() {
    const { selectedAppointment, appointment } = this.props;

    if (selectedAppointment === appointment && selectedAppointment !== null) {
      return "warning";
    } else {
      return appointment === null ? "light" : "success";
    }
  }

  getAppointmentAvailability() {
    const { selectedAppointment, appointment } = this.props;

    if (selectedAppointment === appointment && selectedAppointment !== null) {
      return "Selected";
    } else {
      return appointment === null ? "Unavailable" : "Available";
    }
  }

  getOnClick() {
    const { selectedAppointment, appointment, handleAppointmentSelect } =
      this.props;
    if (appointment !== null && appointment !== selectedAppointment) {
      return () => handleAppointmentSelect(appointment);
    } else if (appointment !== null && appointment === selectedAppointment) {
      return () => handleAppointmentSelect(null);
    } else {
      return null;
    }
  }

  render() {
    return (
      <ListGroup.Item
        variant={this.getVariant()}
        onClick={this.getOnClick()}
        className={this.getClassName()}
      >
        {this.props.timeOfDay}
        {this.getAppointmentAvailability()}
      </ListGroup.Item>
    );
  }
}

export default Appointment;
