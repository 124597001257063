import React, { Component, Fragment } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import {
  bookAppointment,
  cancelAppointment,
  getAppointments,
  getBookedAppointment,
  getContractDetails,
  getServiceOrders,
  rebookAppointment,
  upsertContract,
} from "../api/serviceavenger";
import "./recall.css";
import parse from "html-react-parser";
import moment from "moment";
import AppointmentList from "./AppointmentList";
import ExistingAppointment from "./ExistingAppointment";
import ServiceOrderList from "./ServiceOrderList";
import auth from "../helpers/auth";

export default class Recall extends Component {
  state = {
    address: "",
    address2: "",
    appointmentList: [],
    appointmentWorking: false,
    beginSearchDate: moment().format("MM/DD/YYYY"), // default to today's date then let did mount set the begin date.
    bookedAppointment: null,
    bookingType: "Standard",
    brand: "",
    brands: [
      { brand: "GE", brandCode: "GE" },
      { brand: "Haier", brandCode: "HAIER" },
      { brand: "Hotpoint", brandCode: "HOT" },
      { brand: "GE Cafe", brandCode: "GEC" },
      { brand: "GE Profile", brandCode: "GEP" },
      { brand: "Conservator", brandCode: "CONSERVATOR" },
      { brand: "Crosley", brandCode: "CROSLEY" },
    ],
    brandsFreezer: [{ brand: "GE", brandCode: "GE" }],
    brandCode: "",
    cancelReason: "",
    city: "",
    contractDetails: null,
    contractNumber: "",
    dealerCustomerId: "",
    effectiveDt: "",
    email: "",
    errorMessage: "",
    existingAppointment: null,
    expirationDt: "",
    externalContractNumber: "",
    externalOrderNumber: "",
    firstName: "",
    getAppointmentWorking: false,
    isAddressValidated: false,
    isAddressValid: null,
    isAddress2Validated: false,
    isAddress2Valid: null,
    isBrandValidated: false,
    isBrandValid: null,
    isCancelReasonValidated: false,
    isCancelReasonValid: null,
    isCityValidated: false,
    isCityValid: null,
    isDealerCustomerIdValidated: false,
    isDealerCustomerIdValid: null,
    isEmailValidated: false,
    isEmailValid: null,
    isFirstNameValidated: false,
    isFirstNameValid: null,
    isLastNameValidated: false,
    isLastNameValid: null,
    isModelNumberValidated: false,
    isModelNumberValid: null,
    isPrimaryPhoneValidated: false,
    isPrimaryPhoneValid: null,
    isSecondaryPhoneValidated: false,
    isSecondaryPhoneValid: null,
    isSerialNumberValidated: false,
    isSerialNumberValid: null,
    isStateValidated: false,
    isStateValid: null,
    isZipCodeValidated: false,
    isZipCodeValid: null,
    lastName: "",
    loading: false,
    modelNumber: "",
    modelNumbers: [],
    modelNumberFreezerList: [
      { brandCode: "GE", modelNumberPrefix: "GFE26JYMKFFS" },
      { brandCode: "GE", modelNumberPrefix: "GFE26JYMFS" },
      { brandCode: "GE", modelNumberPrefix: "GNE27EYMFS" },
      { brandCode: "GE", modelNumberPrefix: "GNE27EYMKFFS" },
      { brandCode: "GE", modelNumberPrefix: "GNE27JYMFS" },
      { brandCode: "GE", modelNumberPrefix: "GNE27JYMKFFS" },
      { brandCode: "GE", modelNumberPrefix: "GFE26JYMNFFS" },
      { brandCode: "GE", modelNumberPrefix: "GNE27EYMNFFS" },
      { brandCode: "GE", modelNumberPrefix: "GNE27JYMNFFS" },
    ],
    modelNumberList: [
      { brandCode: "GEC", modelNumberPrefix: "C2S900P" },
      { brandCode: "GEC", modelNumberPrefix: "C2S950P" },
      { brandCode: "GEC", modelNumberPrefix: "CES700P" },
      { brandCode: "GEC", modelNumberPrefix: "CES750P" },
      { brandCode: "GEC", modelNumberPrefix: "CGB500P" },
      { brandCode: "GEC", modelNumberPrefix: "CGS700" },
      { brandCode: "GEC", modelNumberPrefix: "CGS750P" },
      { brandCode: "GEC", modelNumberPrefix: "CHS900P" },
      { brandCode: "GEC", modelNumberPrefix: "CHS950P" },

      { brandCode: "CONSERVATOR", modelNumberPrefix: "VBS160" },

      { brandCode: "CROSLEY", modelNumberPrefix: "XBS360" },
      { brandCode: "CROSLEY", modelNumberPrefix: "XGBS400" },

      { brandCode: "GE", modelNumberPrefix: "JAS640" },
      { brandCode: "GE", modelNumberPrefix: "JB256" },
      { brandCode: "GE", modelNumberPrefix: "JB480" },
      { brandCode: "GE", modelNumberPrefix: "JBS160" },
      { brandCode: "GE", modelNumberPrefix: "JBS360" },
      { brandCode: "GE", modelNumberPrefix: "JBS460" },
      { brandCode: "GE", modelNumberPrefix: "JGAS640" },
      { brandCode: "GE", modelNumberPrefix: "JGB635" },
      { brandCode: "GE", modelNumberPrefix: "JGB645" },
      { brandCode: "GE", modelNumberPrefix: "JGB660" },
      { brandCode: "GE", modelNumberPrefix: "JGB735" },
      { brandCode: "GE", modelNumberPrefix: "JGBS10" },
      { brandCode: "GE", modelNumberPrefix: "JGBS30" },
      { brandCode: "GE", modelNumberPrefix: "JGBS60" },
      { brandCode: "GE", modelNumberPrefix: "JGBS61" },
      { brandCode: "GE", modelNumberPrefix: "JGBS66" },
      { brandCode: "GE", modelNumberPrefix: "JGBS86" },
      { brandCode: "GE", modelNumberPrefix: "JGS760" },
      { brandCode: "GE", modelNumberPrefix: "JGSS66" },
      { brandCode: "GE", modelNumberPrefix: "JGSS86" },

      { brandCode: "GEP", modelNumberPrefix: "P2S930" },
      { brandCode: "GEP", modelNumberPrefix: "P2B935" },
      { brandCode: "GEP", modelNumberPrefix: "PGB935" },
      { brandCode: "GEP", modelNumberPrefix: "PGB965" },
      { brandCode: "GEP", modelNumberPrefix: "PGS930" },
      { brandCode: "GEP", modelNumberPrefix: "PGS960" },

      { brandCode: "HAIER", modelNumberPrefix: "QSS740" },
      { brandCode: "HAIER", modelNumberPrefix: "QAS740" },
      { brandCode: "HAIER", modelNumberPrefix: "QGAS740" },

      { brandCode: "HOT", modelNumberPrefix: "RAS200" },
      { brandCode: "HOT", modelNumberPrefix: "RAS240" },
      { brandCode: "HOT", modelNumberPrefix: "RAS300" },
      { brandCode: "HOT", modelNumberPrefix: "RBS160" },
      { brandCode: "HOT", modelNumberPrefix: "RBS360" },
      { brandCode: "HOT", modelNumberPrefix: "RGAS200" },
      { brandCode: "HOT", modelNumberPrefix: "RGAS300" },
      { brandCode: "HOT", modelNumberPrefix: "RGBS100" },
      { brandCode: "HOT", modelNumberPrefix: "RGBS200" },
      { brandCode: "HOT", modelNumberPrefix: "RGBS300" },
      { brandCode: "HOT", modelNumberPrefix: "RGBS400" },
    ],
    primaryPhone: "",
    recallCampaign: sessionStorage.getItem("username"),
    secondaryPhone: "",
    serialNumber: "",
    selectedServiceOrder: null,
    serviceOrderList: [],
    serviceOrderNumber: "",
    serviceOrderWorking: false,
    showAppointmentList: false,
    showBookSuccess: false,
    showCancelSuccess: false,
    state: "",
    zipcode: "",
  };

  componentDidMount = async () => {
    this.setBeginDate();
  };

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePhone(phone) {
    const cleanString = phone.replace(/\D/g, "");
    return cleanString?.length === 10;
  }

  validatePage = () => {
    const {
      address,
      brand,
      city,
      dealerCustomerId,
      email,
      firstName,
      lastName,
      modelNumber,
      primaryPhone,
      recallCampaign,
      secondaryPhone,
      serialNumber,
      state,
      zipcode,
    } = this.state;

    this.setState({
      isAddressValidated: true,
      isAddressValid: address?.trim().length === 0 ? false : true,
      isBrandValidated: true,
      isBrandValid: brand?.length === 0 ? false : true,
      isCityValidated: true,
      isCityValid: city?.trim().length === 0 ? false : true,
      isEmailValidated: true,
      isEmailValid:
        email?.trim().length === 0 ? false : this.validateEmail(email?.trim()),
      isFirstNameValidated: true,
      isFirstNameValid: firstName?.trim().length === 0 ? false : true,
      isLastNameValidated: true,
      isLastNameValid: lastName?.trim().length === 0 ? false : true,
      isModelNumberValidated: true,
      isModelNumberValid: modelNumber?.trim().length === 0 ? false : true,
      isPrimaryPhoneValidated: true,
      isPrimaryPhoneValid:
        primaryPhone?.trim().length === 0
          ? false
          : this.validatePhone(primaryPhone?.trim()),
      isSecondaryPhoneValidated:
        secondaryPhone?.trim().length > 0 ? true : false,
      isSecondaryPhoneValid:
        secondaryPhone?.trim().length > 0
          ? this.validatePhone(secondaryPhone.trim())
          : null,
      isSerialNumberValidated: true,
      isSerialNumberValid: serialNumber?.trim().length === 0 ? false : true,
      isStateValidated: true,
      isStateValid: state?.length === 0 ? false : true,
      isZipCodeValidated: true,
      isZipCodeValid: zipcode?.trim().length === 5 ? true : false,
    });
    if (recallCampaign === "GeRecallFreezerHandle") {
      this.setState({
        isDealerCustomerIdValidated: true,
        isDealerCustomerIdValid:
          dealerCustomerId?.trim().length > 0 ? true : false,
      });
    }

    return (
      address?.length > 0 &&
      brand?.length > 0 &&
      city?.length > 0 &&
      email?.length > 0 &&
      this.validateEmail(email) &&
      firstName?.length > 0 &&
      lastName?.length > 0 &&
      modelNumber?.length > 0 &&
      primaryPhone?.length > 0 &&
      this.validatePhone(primaryPhone) &&
      (secondaryPhone?.length === 0 ||
        (secondaryPhone?.length > 0 && this.validatePhone(secondaryPhone))) &&
      serialNumber?.length > 0 &&
      state?.length > 0 &&
      zipcode?.length > 0 &&
      (recallCampaign === "GeRecallUser" ||
        (recallCampaign === "GeRecallFreezerHandle" &&
          dealerCustomerId?.trim().length > 0))
    );
  };

  validateCancel = () => {
    const { cancelReason } = this.state;

    this.setState({
      errorMessage: "",
      isCancelReasonValidated: true,
      isCancelReasonValid: cancelReason?.trim().length === 0 ? false : true,
    });

    return cancelReason.length > 0;
  };

  resetFields = () => {
    this.resetValidation();
    this.setState({
      address: "",
      address2: "",
      appointmentList: [],
      beginSearchDate: moment().format("MM/DD/YYYY"),
      bookedAppointment: null,
      bookingType: "Standard",
      brand: "",
      brandCode: "",
      cancelReason: "",
      city: "",
      contractDetails: null,
      contractNumber: "",
      dealerCustomerId: "",
      effectiveDt: "",
      email: "",
      errorMessage: "",
      existingAppointment: null,
      expirationDt: "",
      externalContractNumber: "",
      externalOrderNumber: "",
      firstName: "",
      getAppointmentWorking: false,
      lastName: "",
      loading: false,
      modelNumber: "",
      modelNumbers: [],
      primaryPhone: "",
      secondaryPhone: "",
      serialNumber: "",
      selectedServiceOrder: null,
      serviceOrderList: [],
      serviceOrderNumber: "",
      showBookSuccess: false,
      showCancelSuccess: false,
      state: "",
      zipcode: "",
    });

    this.setBeginDate();
  };

  resetValidation = () => {
    this.setState({
      isAddressValidated: false,
      isAddressValid: null,
      isBrandValidated: false,
      isBrandValid: null,
      isCancelReasonValidated: false,
      isCancelReasonValid: null,
      isCityValidated: false,
      isCityValid: null,
      isDealerCustomerIdValidated: false,
      isDealerCustomerIdValid: null,
      isEmailValidated: false,
      isEmailValid: null,
      isFirstNameValidated: false,
      isFirstNameValid: null,
      isLastNameValidated: false,
      isLastNameValid: null,
      isModelNumberValidated: false,
      isModelNumberValid: null,
      isPrimaryPhoneValidated: false,
      isPrimaryPhoneValid: null,
      isSecondaryPhoneValidated: false,
      isSecondaryPhoneValid: null,
      isSerialNumberValidated: false,
      isSerialNumberValid: null,
      isStateValidated: false,
      isStateValid: null,
      isZipCodeValidated: false,
      isZipCodeValid: null,
      showAppointmentList: false,
    });
  };

  handleServiceOrderSelect = async (serviceOrder) => {
    this.setState({ errorMessage: "" });

    const {
      address1,
      address2,
      city,
      country,
      email,
      firstName,
      lastName,
      mobileNumber,
      serviceOrderNumber,
      state,
      zipCode,
    } = serviceOrder;

    if (
      serviceOrderNumber === this.state.selectedServiceOrder?.serviceOrderNumber
    ) {
      this.setState({
        selectedServiceOrder: null,
        serviceOrderNumber: "",
        existingAppointment: null,
        externalOrderNumber: "",
      });
    } else {
      this.setState({
        address: address1,
        address2,
        city,
        country,
        email,
        firstName,
        lastName,
        primaryPhone: mobileNumber,
        selectedServiceOrder: serviceOrder,
        serviceOrderNumber,
        state,
        zipcode: zipCode,
        getAppointmentWorking: true,
      });
      const appointment = await this.getAppointment(
        this.state.contractNumber,
        serviceOrderNumber
      );
      this.setState({
        existingAppointment: appointment,
        externalOrderNumber: appointment?.authorizationNumber,
        getAppointmentWorking: false,
      });
    }
  };

  handleViewAppointmentClick = async () => {
    let contractNumber = "";
    let contractDetails = null;
    let serviceOrderList = null;

    this.resetValidation();

    if (this.validatePage()) {
      contractNumber = await this.upsertContract();
      if (contractNumber) {
        contractDetails = await this.getContractDetails(contractNumber);
        serviceOrderList = await this.getServiceOrders(contractNumber);
        let recallServiceOrderList = [];
        if (serviceOrderList?.length > 0) {
          recallServiceOrderList = serviceOrderList?.filter(function (
            serviceOrder
          ) {
            return (
              serviceOrder?.claimFailureNote?.toLowerCase() ===
                "recall service" ||
              serviceOrder?.claimStorySymptom?.toUpperCase() === "RECALL"
            );
          });
        } else {
          recallServiceOrderList = [];
        }
        this.setState({
          serviceOrderList: recallServiceOrderList,
        });
        if (contractDetails) {
          this.setState({
            contractDetails,
            contractNumber,
            effectiveDt: moment(contractDetails?.effectiveDate).format(
              "MM/DD/YYYY"
            ),
            expirationDt: moment(contractDetails?.expirationDate).format(
              "MM/DD/YYYY"
            ),
            externalContractNumber: contractDetails?.referenceNumber,
          });
          await this.getServiceAppointments("");
        }
      } else {
        this.setState({
          errorMessage: (this.state.errorMessage +=
            "<br />There was an issue getting contract details."),
        });
        window.location.href = "#errorFound";
      }
    }
  };

  getAppointment = async (contractNumber, serviceOrderNumber) => {
    let errorMessage = this.state.errorMessage;
    if (contractNumber?.length > 0 && serviceOrderNumber?.length > 0) {
      try {
        const response = await getBookedAppointment(
          contractNumber,
          serviceOrderNumber
        );
        const { status, data } = response;
        if (status === 200) {
          if (data?.status !== 200) {
            errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${data?.message}`;
            let errorDetails = data?.data?.details;

            if (errorDetails?.length > 0) {
              errorMessage += "<ul>";
              errorDetails?.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
              });
              errorMessage += "</ul>";
            } else {
              errorDetails = data?.data;
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += "<ul>";
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += "</ul>";
                });
              }
            }

            this.setState({ errorMessage });
            window.location.href = "#errorFound";
            return null;
          } else {
            const appointmentList = data?.data;
            this.setState({
              existingAppointment:
                appointmentList?.length > 0 ? appointmentList[0] : null,
            });
            return appointmentList?.length > 0 ? appointmentList[0] : null;
          }
        } else {
          errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${status}`;
          this.setState({ errorMessage });
          window.location.href = "#errorFound";
        }
      } catch (error) {
        errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${error?.message}`;
        this.setState({ errorMessage });
        window.location.href = "#errorFound";
      }
    }
  };

  getServiceAppointments = async (beginDt) => {
    try {
      let errorMessage = this.state.errorMessage;
      let appointments = [];

      this.setState({ loading: true, showAppointmentList: false });

      const {
        address,
        address2,
        beginSearchDate,
        brandCode,
        city,
        contractDetails,
        contractNumber,
        email,
        firstName,
        lastName,
        modelNumber,
        zipcode,
        primaryPhone,
        recallCampaign,
        secondaryPhone,
        serialNumber,
        state,
      } = this.state;

      const searchDt = beginDt
        ? moment(beginDt).format("YYYY-MM-DD")
        : moment(beginSearchDate).format("YYYY-MM-DD");

      const response = await getAppointments(
        address,
        address2,
        primaryPhone?.replace(/\D/g, ""),
        city,
        email,
        firstName,
        lastName,
        secondaryPhone?.replace(/\D/g, ""),
        state,
        zipcode,
        brandCode,
        modelNumber,
        serialNumber,
        searchDt,
        contractNumber,
        moment(contractDetails?.effectiveDate).format("YYYY-MM-DD"),
        moment(contractDetails?.expirationDate).format("YYYY-MM-DD"),
        recallCampaign
      );

      this.setState({ loading: false });

      if (response?.status === 200) {
        if (response?.data?.status !== 200) {
          const { data } = response;
          errorMessage += `<br/><b>Error getting appointments:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += "<ul>";
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += "</ul>";
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += "<ul>";
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += "</ul>";
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = "#errorFound";
        } else {
          appointments = response?.data?.data;
          this.setState({
            appointmentList: appointments,
            showAppointmentList: true,
          });
        }
      } else {
        errorMessage += `<br/><b>Error with get appointments:</b><br/>${response?.status} - ${response?.message}`;
        this.setState({ errorMessage });
        window.location.href = "#errorFound";
      }
    } catch (error) {
      let errorMessage = "";
      errorMessage += "<br/><b>Error with get appointments:</b><br/>" + error;
      this.setState({
        errorMessage,
      });
      window.location.href = "#errorFound";
    }
  };

  getServiceOrders = async (contractNumber) => {
    this.setState({ serviceOrderWorking: true });
    let errorMessage = "";
    try {
      const response = await getServiceOrders(contractNumber);
      const { data } = response;
      this.setState({ serviceOrderWorking: false });
      if (response?.status === 200) {
        if (data?.status !== 200) {
          errorMessage += `<b>Error while getting service orders:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += "<ul>";
            errorDetails?.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += "</ul>";
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += "<ul>";
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += "</ul>";
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = "#errorFound";
          return null;
        } else {
          const serviceOrderList = response?.data?.data;
          return serviceOrderList?.length > 0 ? serviceOrderList : null;
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get service orders:</b><br/>${response?.status} - ${response?.message}`;
        this.setState({ errorMessage });
        window.location.href = "#errorFound";
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting service orders:</b><br/>${error}`,
      });
      window.location.href = "#errorFound";
    }
  };

  getContractDetails = async (contractNumber) => {
    try {
      let errorMessage = "";

      const response = await getContractDetails(contractNumber);
      const { status, data, statusText } = response;

      if (status === 200) {
        if (data?.status !== 200) {
          errorMessage += `<b>Error while getting contract details:</b><br/>${data?.status} - ${data?.message}`;
          errorMessage += `<br/>Attempted to search for contract ${contractNumber}.`;

          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += "<ul>";
            errorDetails?.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += "</ul>";
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += "<ul>";
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += "</ul>";
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = "#errorFound";
          return null;
        } else {
          return data?.data?.contract;
        }
      } else {
        errorMessage = this.state.errorMessage;
        errorMessage += `<br/><b>Error while getting contract details:</b><br/> ${status} - ${statusText}`;
        this.setState({ errorMessage });
        window.location.href = "#errorFound";
        return null;
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while getting contract details:</b><br/>${error?.message}`,
      });
      window.location.href = "#errorFound";
    }
  };

  getNextWeek = async () => {
    let beginDt = new Date(this.state.beginSearchDate);
    beginDt.setDate(beginDt.getDate() + 7);
    this.setState({
      beginSearchDate: moment(beginDt).format("MM/DD/YYYY"),
      selectedAppointment: null,
    });
    this.getServiceAppointments(beginDt);
  };

  getLastWeek() {
    let beginDt = new Date(this.state.beginSearchDate);
    beginDt.setDate(beginDt.getDate() - 7);
    this.setState({
      beginSearchDate: moment(beginDt).format("MM/DD/YYYY"),
      selectedAppointment: null,
    });
    this.getServiceAppointments(beginDt);
  }

  getPreviousButton() {
    if (
      moment(this.state.beginSearchDate).isSameOrBefore(
        moment().format("MM/DD/YYYY")
      )
    ) {
      return null;
    } else {
      return (
        <Button
          className="m-1"
          disabled={this.state.loading}
          onClick={(e) => {
            e.preventDefault();
            this.getLastWeek();
          }}
          variant="primary"
        >
          Appointments Prior Week
        </Button>
      );
    }
  }

  getNextButton() {
    if (
      moment(this.state.beginSearchDate).isSameOrAfter(
        moment().add(56, "days").format("MM/DD/YYYY")
      )
    ) {
      return null;
    } else {
      return (
        <Button
          className="m-1"
          disabled={this.state.loading}
          onClick={(e) => {
            e.preventDefault();
            this.getNextWeek();
          }}
          variant="primary"
        >
          Appointments Next Week
        </Button>
      );
    }
  }

  setBeginDate() {
    const { beginSearchDate } = this.state;

    let dayOfWeek = moment(beginSearchDate).day();
    if (dayOfWeek < 1) {
      this.setState({
        beginSearchDate: moment(beginSearchDate)
          .add(1, "days")
          .format("MM/DD/YYYY"),
      });
    }
    if (dayOfWeek > 1) {
      let daysToSubtract = dayOfWeek - 1;
      this.setState({
        beginSearchDate: moment(beginSearchDate)
          .add(daysToSubtract * -1, "days")
          .format("MM/DD/YYYY"),
      });
    }
  }

  upsertContract = async () => {
    const {
      address,
      address2,
      brand,
      primaryPhone,
      city,
      dealerCustomerId,
      email,
      firstName,
      lastName,
      modelNumber,
      secondaryPhone,
      recallCampaign,
      serialNumber,
      state,
      zipcode,
    } = this.state;

    this.setState({ loading: true, errorMessage: "" });

    let errorMessage = "";

    try {
      const response = await upsertContract(
        address?.trim(),
        address2?.length > 0 ? address2?.trim() : address2,
        brand,
        primaryPhone?.replace(/\D/g, ""),
        city?.trim(),
        dealerCustomerId?.trim(),
        email?.trim(),
        firstName?.trim(),
        lastName?.trim(),
        modelNumber?.trim(),
        secondaryPhone?.length > 0
          ? secondaryPhone?.replace(/\D/g, "")
          : secondaryPhone,
        recallCampaign,
        serialNumber?.trim(),
        state,
        zipcode?.trim()
      );
      this.setState({ loading: false });

      const { status, data, message } = response;

      if (status === 200) {
        if (data?.status !== 200) {
          errorMessage += `<b>Error while getting contract details:</b><br/>${data?.status} - ${data?.message}`;

          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += "<ul>";
            errorDetails?.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += "</ul>";
          } else {
            errorDetails = data?.data;
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += "<ul>";
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`;
                });
                errorMessage += "</ul>";
              });
            }
          }

          this.setState({ errorMessage });
          window.location.href = "#errorFound";
          return null;
        } else {
          const dataObject = data?.data;
          let contractNumber = Array.isArray(dataObject)
            ? data?.data[0].serviceAvengerContract
            : data?.opwContractID;
          return contractNumber;
        }
      } else {
        errorMessage = this.state.errorMessage;
        errorMessage += `<br/><b>Error while getting contract details:</b><br/> ${status} - ${message}`;
        this.setState({ errorMessage });
        window.location.href = "#errorFound";
        return null;
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while upserting contract.</b><br/>${error?.message}`,
      });
      window.location.href = "#errorFound";
    }
  };

  handleAppointmentSelect = (appointment) => {
    this.setState({ selectedAppointment: appointment });
  };

  handleCancelAppointment = async () => {
    let errorMessage = "";

    if (this.validateCancel()) {
      try {
        this.setState({ appointmentWorking: true, errorMessage: "" });

        const {
          cancelReason,
          contractNumber,
          existingAppointment,
          externalContractNumber,
          externalOrderNumber,
          firstName,
          lastName,
          primaryPhone,
          serviceOrderNumber,
        } = this.state;

        const response = await cancelAppointment(
          contractNumber,
          externalContractNumber,
          externalOrderNumber,
          serviceOrderNumber,
          firstName,
          lastName,
          primaryPhone?.replace(/\D/g, ""),
          existingAppointment,
          cancelReason
        );

        if (response?.status === 200) {
          if (response?.data?.status !== 200) {
            const { data } = response;
            errorMessage += `<b>Error while attempting to cancel:</b><br/>${data?.status} - ${data?.message}`;
            let errorDetails = data.data.details;

            if (errorDetails?.length > 0) {
              errorMessage += "<ul>";
              errorDetails?.forEach((error) => {
                errorMessage += `<li>${error.code} - ${error.message}</li>`;
              });
              errorMessage += "</ul>";
            } else {
              errorDetails = data?.data;
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += "<ul>";
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[
                      key
                    ].toString()}</li>`;
                  });
                  errorMessage += "</ul>";
                });
              }
            }

            this.setState({ errorMessage, appointmentWorking: false });
            window.location.href = "#errorFound";
          } else {
            const appointment = await this.getAppointment(
              contractNumber,
              serviceOrderNumber
            );

            const newServiceOrderList = await this.getServiceOrders(
              contractNumber
            );

            let newServiceOrderResults = [];

            if (newServiceOrderList.length > 0) {
              newServiceOrderResults = newServiceOrderList.filter(
                (serviceorder) =>
                  serviceorder?.serviceOrderNumber === serviceOrderNumber
              );
            }

            const newServiceOrder =
              newServiceOrderResults?.length > 0
                ? newServiceOrderResults[0]
                : null;

            this.setState({
              contractNumber,
              appointmentWorking: false,
              existingAppointment: appointment,
              externalOrderNumber: appointment?.authorizationNumber,
              selectedAppointment: null,
              selectedServiceOrder: newServiceOrder,
              serviceOrderList: newServiceOrderList,
              showCancelSuccess: true,
            });

            setTimeout(() => {
              this.setState({
                showCancelSuccess: false,
              });
            }, 3000);
          }
        } else {
          errorMessage += `<br/><b>Error before attempting to cancel appointment:</b><br/>${response?.status} - ${response?.message}`;
          this.setState({ errorMessage, appointmentWorking: false });
          window.location.href = "#errorFound";
        }
      } catch (error) {
        this.setState({
          errorMessage: `<br/><b>Error attempting to cancel appointment:</b><br/>${error?.message}`,
          appointmentWorking: false,
        });
        window.location.href = "#errorFound";
      }
    }
  };

  handleBookAppointment = async () => {
    let errorMessage = "";
    const {
      address,
      address2,
      brandCode,
      city,
      contractNumber,
      effectiveDt,
      email,
      expirationDt,
      existingAppointment,
      externalContractNumber,
      externalOrderNumber,
      firstName,
      lastName,
      modelNumber,
      zipcode,
      primaryPhone,
      recallCampaign,
      secondaryPhone,
      selectedAppointment,
      serialNumber,
      serviceOrderNumber,
      state,
    } = this.state;

    if (
      existingAppointment !== null &&
      existingAppointment?.appointmentTimeSlot !== "CANCELLED"
    ) {
      if (
        window.confirm(
          `There is an existing appointment on ${moment
            .utc(existingAppointment.appointmentDate)
            .format("MM/DD/YYYY")} from ${
            existingAppointment.appointmentTimeSlot
          }.
        Are you sure you wish to change to ${moment
          .utc(selectedAppointment.appointmentInfo.timeSlotData.availableDate)
          .format("MM/DD/YYYY")} from ${
            selectedAppointment.appointmentInfo.timeSlotData.timePeriod
          }?`
        )
      ) {
        try {
          if (this.validatePage()) {
            this.setState({ appointmentWorking: true, errorMessage: "" });
            const response = await rebookAppointment(
              contractNumber,
              externalContractNumber,
              externalOrderNumber,
              serviceOrderNumber,
              firstName,
              lastName,
              primaryPhone?.replace(/\D/g, ""),
              selectedAppointment
            );

            if (response.status === 200) {
              if (response.data.status !== 200) {
                const { data } = response;
                errorMessage += `<b>Error while attempting to re-book:</b><br/>${data?.status} - ${data?.message}`;
                let errorDetails = data.data.details;

                if (errorDetails?.length > 0) {
                  errorMessage += "<ul>";
                  errorDetails.forEach((error) => {
                    errorMessage += `<li>${error.code} - ${error.message}</li>`;
                  });
                  errorMessage += "</ul>";
                } else {
                  errorDetails = data?.data;
                  if (errorDetails.length > 0) {
                    errorDetails?.forEach((error) => {
                      errorMessage += "<ul>";
                      Object.keys(error).forEach((key) => {
                        errorMessage += `<li>${key} - ${error[
                          key
                        ].toString()}</li>`;
                      });
                      errorMessage += "</ul>";
                    });
                  }
                }

                this.setState({ errorMessage, appointmentWorking: false });
                window.location.href = "#errorFound";
              } else {
                this.setState({ getAppointmentWorking: true });
                const appointment = await this.getAppointment(
                  contractNumber,
                  serviceOrderNumber
                );

                this.setState({
                  appointmentWorking: false,
                  getAppointmentWorking: false,
                  existingAppointment: appointment,
                  externalOrderNumber: appointment?.authorizationNumber,
                  showBookSuccess: true,
                });

                setTimeout(() => {
                  this.setState({
                    showBookSuccess: false,
                  });
                }, 3000);
              }
            } else {
              errorMessage += `<br/><b>Error before attempting to re-book appointment:</b><br/>${response.status} - ${response.message}`;
              this.setState({ errorMessage, appointmentWorking: false });
              window.location.href = "#errorFound";
            }
          }
        } catch (error) {
          this.setState({
            errorMessage: `<br/><b>Error attempting to re-book appointment:</b><br/>${error.message}`,
            appointmentWorking: false,
          });
          window.location.href = "#errorFound";
        }
      }
    } else {
      try {
        if (this.validatePage()) {
          this.setState({ appointmentWorking: true, errorMessage: "" });
          const response = await bookAppointment(
            firstName,
            lastName,
            address,
            address2,
            city,
            state,
            zipcode,
            primaryPhone?.replace(/\D/g, ""),
            secondaryPhone?.length > 0
              ? secondaryPhone?.replace(/\D/g, "")
              : secondaryPhone,
            email,
            brandCode,
            modelNumber,
            serialNumber,
            effectiveDt,
            expirationDt,
            contractNumber,
            externalContractNumber,
            selectedAppointment,
            recallCampaign
          );

          if (response?.status === 200) {
            if (response?.data?.status !== 200) {
              const { data } = response;
              errorMessage += `<br/><b>Error while attempting to book appointment:</b><br/>${data?.status} - ${data?.message}`;
              let errorDetails = data?.data?.details;

              if (errorDetails) {
                if (errorDetails?.length > 0) {
                  errorMessage += "<ul>";
                  errorDetails?.forEach((error) => {
                    errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
                  });
                  errorMessage += "</ul>";
                }
              } else {
                errorDetails = data?.data;
                if (errorDetails.length > 0) {
                  errorDetails?.forEach((error) => {
                    errorMessage += "<ul>";
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[
                        key
                      ].toString()}</li>`;
                    });
                    errorMessage += "</ul>";
                  });
                }
              }

              this.setState({ errorMessage, appointmentWorking: false });
              window.location.href = "#errorFound";
            } else {
              const newServiceOrderNumber = response?.data?.data?.callNumber;
              const newServiceOrderList = await this.getServiceOrders(
                contractNumber
              );
              let newServiceOrderResults = [];
              if (newServiceOrderList.length > 0) {
                newServiceOrderResults = newServiceOrderList.filter(
                  (serviceorder) =>
                    serviceorder?.serviceOrderNumber === newServiceOrderNumber
                );
              }

              const newServiceOrder =
                newServiceOrderResults?.length > 0
                  ? newServiceOrderResults[0]
                  : null;

              const appointment = await this.getAppointment(
                contractNumber,
                newServiceOrderNumber
              );

              this.setState({
                appointmentWorking: false,
                getAppointmentWorking: false,
                existingAppointment: appointment,
                serviceOrderList: newServiceOrderList,
                serviceOrderNumber: newServiceOrderNumber,
                selectedServiceOrder: newServiceOrder,
                externalOrderNumber: appointment?.authorizationNumber,
                showBookSuccess: true,
              });

              setTimeout(() => {
                this.setState({
                  showBookSuccess: false,
                });
              }, 3000);
            }
          } else {
            errorMessage += `<br/><b>Error before attempting to book appointment:</b><br/>${response?.status} - ${response?.message}`;
            this.setState({ errorMessage, appointmentWorking: false });
            window.location.href = "#errorFound";
          }
        }
      } catch (error) {
        this.setState({
          errorMessage: `<br/><b>Error attempting to book appointment:</b><br/>${error?.message}`,
          appointmentWorking: false,
        });
        window.location.href = "#errorFound";
      }
    }
  };

  handleInputChange(event) {
    event.preventDefault();

    const { target } = event;
    const { id, value } = target;

    if (id === "brand") {
      const brandDetails = this.state.brands?.find(function (brandDetail) {
        return brandDetail?.brand?.toUpperCase() === value.toUpperCase();
      });

      const { modelNumberFreezerList, modelNumberList } = this.state;
      let modelNumbers = [];
      let modelNumberRecallList = [];
      if (this.state.recallCampaign === "GeRecallFreezerHandle") {
        modelNumberRecallList = modelNumberFreezerList;
      }
      if (this.state.recallCampaign === "GeRecallUser") {
        modelNumberRecallList = modelNumberList;
      }
      if (modelNumberRecallList.length > 0) {
        modelNumbers = modelNumberRecallList?.filter(
          (product) =>
            product?.brandCode?.toUpperCase() ===
            brandDetails?.brandCode?.toUpperCase()
        );
      }

      this.setState({
        brand: value,
        brandCode: brandDetails?.brandCode,
        contractNumber: "",
        contractDetails: null,
        modelNumbers,
        showAppointmentList: false,
        selectedAppointment: null,
      });
    }

    if (id === "primaryPhone" || id === "secondaryPhone") {
      this.setState({
        contractNumber: "",
        contractDetails: null,
        showAppointmentList: false,
        selectedAppointment: null,
        [id]: value
          ?.replace(/\D+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
      });
    }

    if (
      id !== "primaryPhone" &&
      id !== "secondaryPhone" &&
      id !== "brand" &&
      id !== "cancelReason"
    ) {
      this.setState({
        contractNumber: "",
        contractDetails: null,
        showAppointmentList: false,
        selectedAppointment: null,
        [id]: value,
      });
    }

    if (id === "cancelReason") {
      this.setState({
        [id]: value,
      });
    }

    if (id === "email") {
      this.setState({
        [id]: value?.trim(),
      });
    }
  }

  render() {
    const states = [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];

    const {
      address,
      address2,
      appointmentList,
      appointmentWorking,
      beginSearchDate,
      brand,
      brands,
      brandsFreezer,
      cancelReason,
      city,
      contractNumber,
      dealerCustomerId,
      email,
      errorMessage,
      existingAppointment,
      firstName,
      getAppointmentWorking,
      isAddressValidated,
      isAddressValid,
      isBrandValidated,
      isBrandValid,
      isCancelReasonValidated,
      isCancelReasonValid,
      isCityValidated,
      isCityValid,
      isDealerCustomerIdValidated,
      isDealerCustomerIdValid,
      isEmailValidated,
      isEmailValid,
      isFirstNameValidated,
      isFirstNameValid,
      isLastNameValidated,
      isLastNameValid,
      isModelNumberValidated,
      isModelNumberValid,
      isPrimaryPhoneValidated,
      isPrimaryPhoneValid,
      isSecondaryPhoneValidated,
      isSecondaryPhoneValid,
      isSerialNumberValidated,
      isSerialNumberValid,
      isStateValidated,
      isStateValid,
      isZipCodeValidated,
      isZipCodeValid,
      lastName,
      loading,
      modelNumber,
      modelNumberFreezerList,
      modelNumbers,
      primaryPhone,
      recallCampaign,
      secondaryPhone,
      selectedAppointment,
      selectedServiceOrder,
      serialNumber,
      serviceOrderList,
      serviceOrderWorking,
      showAppointmentList,
      showBookSuccess,
      showCancelSuccess,
      state,
      zipcode,
    } = this.state;

    return (
      <Form>
        <div className="header mb-5"></div>
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={12}>
              <Card className="mb-5">
                <Row>
                  <Col md={12}>
                    <Button
                      variant="secondary"
                      className="m-3"
                      onClick={() => {
                        auth.logout(() => {
                          this.props.history.push("/");
                        });
                      }}
                    >
                      Logout
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1 className="mt-3 mb-5 text-center">
                      Schedule Recall Service
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="ps-5 pe-5 mb-1">
                    <Row>
                      <Form.Group
                        as={Col}
                        controlId="firstName"
                        className="required"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          autoComplete="none"
                          type="text"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isFirstNameValidated
                              ? null
                              : isFirstNameValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a first name.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="lastName"
                        className="required"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          autoComplete="none"
                          type="text"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isLastNameValidated
                              ? null
                              : isLastNameValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a last name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group controlId="address" className="required">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          autoComplete="none"
                          type="text"
                          placeholder="Street Address"
                          value={address}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isAddressValidated
                              ? null
                              : isAddressValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide an address.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group controlId="address2">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                          size="sm"
                          autoComplete="none"
                          type="text"
                          placeholder="Additional Address"
                          value={address2}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group
                        as={Col}
                        controlId="city"
                        className="required"
                      >
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          autoComplete="none"
                          type="text"
                          placeholder="City"
                          value={city}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isCityValidated
                              ? null
                              : isCityValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a city.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="state"
                        className="required"
                      >
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          as="select"
                          placeholder="State"
                          value={state}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isStateValidated
                              ? null
                              : isStateValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        >
                          <option key="" value="">
                            Select state...
                          </option>
                          {states.map((state, i) => (
                            <option key={i} value={state}>
                              {state}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please select a state.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="zipcode"
                        className="required"
                      >
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          autoComplete="none"
                          type="text"
                          placeholder="Postal Code"
                          value={zipcode}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isZipCodeValidated
                              ? null
                              : isZipCodeValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a 5-digit postal code.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group
                        as={Col}
                        controlId="primaryPhone"
                        className="required"
                      >
                        <Form.Label>Primary Phone</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          autoComplete="none"
                          type="text"
                          placeholder="primaryPhone"
                          value={primaryPhone}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isPrimaryPhoneValidated
                              ? null
                              : isPrimaryPhoneValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid phone number.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="secondaryPhone">
                        <Form.Label>Secondary Phone</Form.Label>
                        <Form.Control
                          size="sm"
                          autoComplete="none"
                          type="text"
                          placeholder="secondaryPhone"
                          value={secondaryPhone}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isSecondaryPhoneValidated
                              ? null
                              : isSecondaryPhoneValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid phone number.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group controlId="email" className="mb-3 required">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          autoComplete="none"
                          type="text"
                          placeholder="test@test.com"
                          value={email}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isEmailValidated
                              ? null
                              : isEmailValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid email.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col md={6} className="ps-5 pe-5 mb-1">
                    <Row>
                      <Form.Group controlId="brand" className="required">
                        <Form.Label>Brand</Form.Label>
                        {recallCampaign === "GeRecallUser" && (
                          <React.Fragment>
                            <Form.Control
                              size="sm"
                              required
                              as="select"
                              placeholder="Select Brand..."
                              value={brand}
                              onChange={(event) => {
                                this.handleInputChange(event);
                              }}
                              className={
                                !isBrandValidated
                                  ? null
                                  : isBrandValid
                                  ? "is-valid"
                                  : "is-invalid"
                              }
                            >
                              <option key="" value="">
                                Select brand...
                              </option>
                              {brands.map((brand, i) => (
                                <option key={i} value={brand.brand}>
                                  {brand.brand}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please select a brand.
                            </Form.Control.Feedback>
                          </React.Fragment>
                        )}
                        {recallCampaign === "GeRecallFreezerHandle" && (
                          <React.Fragment>
                            <Form.Control
                              size="sm"
                              required
                              as="select"
                              placeholder="Select Brand..."
                              value={brand}
                              onChange={(event) => {
                                this.handleInputChange(event);
                              }}
                              className={
                                !isBrandValidated
                                  ? null
                                  : isBrandValid
                                  ? "is-valid"
                                  : "is-invalid"
                              }
                            >
                              <option key="" value="">
                                Select brand...
                              </option>
                              {brandsFreezer.map((brand, i) => (
                                <option key={i} value={brand.brand}>
                                  {brand.brand}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please select a brand.
                            </Form.Control.Feedback>
                          </React.Fragment>
                        )}
                      </Form.Group>
                    </Row>
                    <Row>
                      {recallCampaign === "GeRecallUser" && (
                        <Form.Group
                          controlId="modelNumber"
                          className="required"
                        >
                          <Form.Label>Model Number Prefix</Form.Label>
                          <Form.Control
                            size="sm"
                            required
                            as="select"
                            placeholder="Select Model..."
                            value={modelNumber}
                            onChange={(event) => {
                              this.handleInputChange(event);
                            }}
                            className={
                              !isModelNumberValidated
                                ? null
                                : isModelNumberValid
                                ? "is-valid"
                                : "is-invalid"
                            }
                          >
                            <option key="" value="">
                              Select Model Prefix...
                            </option>
                            {modelNumbers.map((modelNumber, i) => (
                              <option
                                key={i}
                                value={modelNumber.modelNumberPrefix}
                              >
                                {modelNumber.modelNumberPrefix}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a model number.
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {recallCampaign === "GeRecallFreezerHandle" && (
                        <Form.Group
                          controlId="modelNumber"
                          className="required"
                        >
                          <Form.Label>Model Number Prefix</Form.Label>
                          <Form.Control
                            size="sm"
                            required
                            as="select"
                            placeholder="Select Model..."
                            value={modelNumber}
                            onChange={(event) => {
                              this.handleInputChange(event);
                            }}
                            className={
                              !isModelNumberValidated
                                ? null
                                : isModelNumberValid
                                ? "is-valid"
                                : "is-invalid"
                            }
                          >
                            <option key="" value="">
                              Select Model Prefix...
                            </option>
                            {modelNumberFreezerList.map((modelNumber, i) => (
                              <option
                                key={i}
                                value={modelNumber.modelNumberPrefix}
                              >
                                {modelNumber.modelNumberPrefix}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a model number.
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    </Row>
                    <Row>
                      <Form.Group
                        controlId="serialNumber"
                        className="mb-3 required"
                      >
                        <Form.Label>Serial Number</Form.Label>
                        <Form.Control
                          size="sm"
                          required
                          autoComplete="none"
                          type="text"
                          placeholder="serial number"
                          value={serialNumber}
                          onChange={(event) => {
                            this.handleInputChange(event);
                          }}
                          className={
                            !isSerialNumberValidated
                              ? null
                              : isSerialNumberValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a serial number.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    {recallCampaign === "GeRecallFreezerHandle" && (
                      <Row>
                        <Form.Group
                          controlId="dealerCustomerId"
                          className="mb-3 required"
                        >
                          <Form.Label>CID</Form.Label>
                          <Form.Control
                            size="sm"
                            required
                            autoComplete="none"
                            type="text"
                            placeholder="CID"
                            value={dealerCustomerId}
                            onChange={(event) => {
                              this.handleInputChange(event);
                            }}
                            className={
                              !isDealerCustomerIdValidated
                                ? null
                                : isDealerCustomerIdValid
                                ? "is-valid"
                                : "is-invalid"
                            }
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a customer ID.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="ps-5 mb-3">
                    {" "}
                    <span className="asterisk">*</span> Required field
                  </Col>
                </Row>
                {contractNumber?.length === 0 ? (
                  <Row>
                    <Col className="text-center">
                      <Button
                        variant="primary"
                        size="lg"
                        className="mb-5"
                        disabled={loading}
                        onClick={() => {
                          this.handleViewAppointmentClick();
                        }}
                      >
                        Find Appointment
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                {contractNumber?.length !== 0 ? (
                  <Row className="ps-3 mb-3 pe-3">
                    <Col md={6}>
                      <ServiceOrderList
                        serviceOrderList={serviceOrderList}
                        serviceOrderWorking={serviceOrderWorking}
                        selectedServiceOrder={selectedServiceOrder}
                        handleServiceOrderSelect={this.handleServiceOrderSelect.bind(
                          this
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <ExistingAppointment
                        appointmentWorking={appointmentWorking}
                        getAppointmentWorking={getAppointmentWorking}
                        cancelReason={cancelReason}
                        existingAppointment={existingAppointment}
                        handleCancelAppointment={this.handleCancelAppointment}
                        isCancelReasonValidated={isCancelReasonValidated}
                        isCancelReasonValid={isCancelReasonValid}
                        handleInputChange={this.handleInputChange.bind(this)}
                      ></ExistingAppointment>
                      <Button
                        variant="primary"
                        className="mt-3"
                        onClick={() => this.resetFields()}
                      >
                        Complete This Call
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                {errorMessage?.length > 0 ? (
                  <Row className="mt-3">
                    <Col>
                      <Alert id="errorFound" variant="danger">
                        {parse(errorMessage)}
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                {showBookSuccess ? (
                  <Row className="mt-3 ps-3 pe-3">
                    <Col>
                      <Alert id="bookSuccess" variant="success">
                        <h1>Success!</h1>
                        <p>Appointment was booked successfully.</p>
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                {showCancelSuccess ? (
                  <Row className="mt-3 ps-3 pe-3">
                    <Col>
                      <Alert id="bookSuccess" variant="success">
                        <h1>Success!</h1>
                        <p>Appointment was cancelled successfully.</p>
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                {showAppointmentList &&
                !loading &&
                existingAppointment?.appointmentTimeSlot !== "CANCELLED" ? (
                  <Fragment>
                    <AppointmentList
                      appointmentList={appointmentList}
                      appointmentWorking={appointmentWorking}
                      beginSearchDate={beginSearchDate}
                      handleAppointmentSelect={this.handleAppointmentSelect}
                      key={beginSearchDate}
                      selectedAppointment={selectedAppointment}
                    />
                    <Row className="mt-3 mb-3 ps-3 pe-3">
                      <Col>
                        {!appointmentWorking && this.getPreviousButton()}
                        {!appointmentWorking && this.getNextButton()}
                      </Col>
                    </Row>
                  </Fragment>
                ) : null}
                {!showAppointmentList && loading ? (
                  <Row className="mt-5 ps-3 pe-3 mb-5 mx-auto">
                    <Col>
                      <Spinner animation="border" variant="secondary"></Spinner>
                      {"  "}Finding appointments...
                    </Col>
                  </Row>
                ) : null}
                {showAppointmentList &&
                selectedAppointment &&
                !appointmentWorking &&
                existingAppointment?.appointmentTimeSlot !== "CANCELLED" ? (
                  <Row className="mb-3 ps-3 pe-3">
                    <Col>
                      <Button
                        className="m-1"
                        disabled={loading}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleBookAppointment();
                        }}
                        variant="primary"
                      >
                        Book Appointment
                      </Button>
                    </Col>
                  </Row>
                ) : null}
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}
