import React, { Component } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./login.css";
import auth from "../helpers/auth";

export default class Login extends Component {
  state = {
    username: "",
    password: "",
    errorMessage: "",
  };

  onInputchange(event) {
    event.preventDefault();

    const { target } = event;
    const { id, value } = target;

    this.setState({
      [id]: value,
    });
  }

  render() {
    const { username, password, errorMessage } = this.state;
    return (
      <Form>
        <div className="header mb-5"></div>
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <Card>
                <Row>
                  <Col>
                    <h3 className="mt-3 text-center">Sign In</h3>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center mb-3">
                  <Col md={8}>
                    <Form.Group controlId="username">
                      <Form.Label>
                        <h6 className="text-left">Username</h6>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Username"
                        value={username}
                        onChange={this.onInputchange.bind(this)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center mb-3">
                  <Col md={8}>
                    <Form.Group controlId="password">
                      <Form.Label>
                        <h6>Password</h6>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={this.onInputchange.bind(this)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                {errorMessage.length > 0 ? (
                  <Row className="mt-3 d-flex justify-content-center">
                    <Col md={8}>
                      <Alert id="errorFound" variant="danger">
                        {errorMessage}
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                <Row className="d-flex justify-content-center">
                  <Col md={8}>
                    <Button
                      className="mt-3 mb-3"
                      onClick={() => {
                        auth.login(
                          username,
                          password,
                          () => {
                            this.props.history.push("/recall");
                          },
                          () => {
                            this.setState({
                              errorMessage:
                                "Username and password combination incorrect.",
                            });
                          }
                        );
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">2022.05.10</Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}
